.edit-build-page {
    color: rgb(212, 212, 212);
    display: grid;
    grid-template-areas: "buildLeftContainer buildMainContainer buildRightContainer";
    grid-template-columns: 1fr 2.5fr 1fr;
    height: 100%;
    grid-template-rows: 100%;
}
.build-left-container {
    grid-area: buildLeftContainer;
}
.build-main-container {
    grid-area: buildMainContainer;
    margin: 20px;
    display: grid;
    grid-template-rows: auto 1fr;
    box-shadow: -1px -1px 0 0 #564640;
}

.build-right-container {
    grid-area: buildRightContainer;
    padding: 20px;
}
.build-group-container .build-list-item .talents, .build-group-container .build-list-item .weapons, .build-group-container .build-list-item .traits {
  display: none;
}
.build-group-container .build-list-item {
  grid-template-columns: 100px auto;
}
.build-group-container {
    height: 100%;
    display: grid;
    grid-template-rows: 60px calc(100% - 100px);
    grid-template-columns: 1fr;
    grid-template-areas: "buildGroupButtons" "buildListContainer";
    padding: 20px;
}
.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    opacity:0;
}
.simplebar-track.simplebar-vertical {
    right: 6px;
  }
  .simplebar-visible {
    background: white;
  }
  .simplebar-offset {
      padding-top: 4px;
  }
  .build-group-container [data-simplebar] {
    grid-area: buildListContainer;
    position: relative;
    background: url('../../assets/images/backgrounds/background12.png');
  }  
  .build-group-container [data-simplebar]::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-image: url('../../assets/images/borders/border-01.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    box-shadow: -1px 0px 0 0 #564640;
  }  
  .build-group-container .build-list {
    padding: 20px;
  }
  .build-group-buttons-container {
    grid-area: buildGroupButtons;
    display: grid;
    align-content: center;
    padding: 20px;
    border-image: url('../../assets/images/borders/border-01.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    box-shadow: -1px -1px 0 0 #564640;
    background: url('../../assets/images/backgrounds/background34.png');
  }
  .build-group-buttons-container p {
    box-shadow: inset 0 0 75px black, inset 0 3px 1px white, inset 0 -2px 1px white;
    background: url('../../assets/images/labels/label-06.png');
    color: #928962;
    height: 42px;
    position: relative;
    display: grid;
    align-content: center;
    text-transform: uppercase;
    font-size: 120%;
  }
  .build-group-buttons-container p::before {
    content: '';
    background: url('../../assets/images/borders/border-29.png');
    position: absolute;
    top: -1px;
    left: -6px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }
  .build-group-buttons-container p::after {
    content: '';
    background: url('../../assets/images/borders/border-29.png');
    transform: rotate(180deg);
    position: absolute;
    top: -1px;
    left: 6px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }
  .build-group-buttons-container p:hover {
    box-shadow: inset 0 0 75px black, inset 0 3px 1px white, inset 0 -2px 1px white, inset 0 -9px 20px -6px #6a371d;
    cursor: pointer;
}
.edit-build-page .build-details-container {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "heroDetails";
  }
  .edit-build-page .build-details-container .hero-details-container {
    grid-template-rows: 1fr;
    grid-template-areas: "heroName" "heroSummary";
    box-shadow: -1px 0px 0 0 #564640;
  }
  .edit-build-page .hero-summary-container {
    grid-template-columns: 80px 1fr;
}
.edit-build-page .hero-summary p:nth-child(2n+1) {
    display: none;
}
.edit-build-page .hero-portrait {
    height: 75px;
    width: 80px;
    align-self: start;
    background-size: cover;
}
.edit-build-page .hero-summary {
    grid-template-rows: 32px 32px;
    grid-row-gap: 7px;
}

.build-navigation-container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    box-shadow: -1px -1px 0 0 #564640;
  }

  .build-summary-container {
    display: grid;
}
.build-summary-tab {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-areas: "buildDetails" "buildSummary";
    border-image: url('../../assets/images/borders/border-01.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    background: url('../../assets/images/backgrounds/background14.png');
}
.build-details-container .hero-passive-container, .build-details-container .hero-skill-container, .build-details-container .hero-perks-container {
    display: none;
}
.build-main-container div[role="tabpanel"] {
    height: calc(100% - 35px);
}
.input-build-description {
    margin: 10px;
}
.build-options-container {
    grid-row-gap: 4px;
    display: grid;
    margin: 10px;
    grid-template-columns: 0.8fr;
    grid-auto-rows: 1fr;
    justify-content: center;
}
.input-build-description {
    margin: 10px;
    border-image: url('../../assets/images/borders/border-02.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    background: url('../../assets/images/backgrounds/background18.png');
    color: #f0f0f0;
    padding: 5px;
    font-size: 18px;
    resize: none;
}
.edit-build-page .react-tabs__tab-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background: url('../../assets/images/labels/label-06.png');
    color: #928962;
    margin: 0;
}
.react-tabs__tab-list li, .container-tabs-list li  {
    border-radius: 0;
    border-image: url('../../assets/images/borders/border-01.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
    box-shadow: inset 0 0 75px black, inset 0 6px 3px white;
    background: black;
}
.container-tabs-list li {
  color: #928962;
  padding: 0;
  margin: -1px 0 0;
  height: 32px;
  display: grid;
  align-content: center;
}
.container-tabs .react-tabs__tab--selected {
  background: #000;
  box-shadow: inset 0 9px 6px -6px white, inset 0 -9px 20px -6px #6a371d !important;
  color: #f0f0f0;
  background: black;
}

.edit-build-page .react-tabs__tab--selected {
    background: #000;
    box-shadow: inset 0 9px 6px -6px white, inset 0 -9px 20px -6px #6a371d !important;
    color: #f0f0f0;
    background: black;
}
.container-tabs-list {
  padding: 0;
  margin: 0;
  display: grid;
  grid-auto-flow: column;
}
.react-tabs__tab:focus {
    box-shadow: initial;
    outline: none;
}
.react-tabs__tab:focus::after {
    content: unset;
}
.react-tabs {    
    max-height: 100%;
    height: 100%;
}
.edit-build-page .react-tabs {    
    box-shadow: -1px -1px 0 0 #564640;
    display: block;
}