
.weapon-background {
    background: url('../../assets/images/backgrounds/icon-background-2.png');
    background-size: calc(100% + 2px);
    position: relative;
    cursor: pointer;
  }
  .weapon-container .weapon-icon {
    height: 56px;
    width: 56px;
    position: relative;
  }
  .weapon-icon:before:hover {
    display: none !important;
  }
  .weapon-icon::after {
    content: '';
    width: 100%;
    height: 100%;
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
  }
  .build-list-item .weapon-icon, .build-list-item .talent-icon, .build-list-item .trait-icon {
      box-shadow: inset 0 2px 1px white;
  }
  .build-list-item .weapon-icon {
    background-size: contain !important;
    border-image: url('../../assets/images/borders/border-10.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
  }
.weapon-icon {
    background-size: contain !important;
    border-image: url('../../assets/images/borders/border-04.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
  }
  .weapon-icon.selected {
    border-image: url('../../assets/images/borders/border-30.png');
    border-image-slice: 15;
    border-image-width: 15px;
    border-style: solid;
  }
  .weapon-background > div {
    background-size: contain;
    height: 100%;
  }
  .weapon-background > div > div {
    height: calc(100% - 6px);
}
  .weapon-icon {    
    box-shadow: inset 0 2px 1px white;
  }
  .bw_1h_mace {
    background: url('../../assets/images/weapons/bw_1h_mace.png');
  }
  .bw_dagger {
    background: url('../../assets/images/weapons/bw_dagger.png');
  }
  .bw_flame_sword {
    background: url('../../assets/images/weapons/bw_flame_sword.png');
  }
  .bw_sword {
    background: url('../../assets/images/weapons/bw_sword.png');
  }
  .dr_1h_axe {
    background: url('../../assets/images/weapons/dr_1h_axe.png');
  }
  .dr_1h_hammer {
    background: url('../../assets/images/weapons/dr_1h_hammer.png');
  }
  .dr_2h_axe {
    background: url('../../assets/images/weapons/dr_2h_axe.png');
  }
  .dr_2h_hammer {
    background: url('../../assets/images/weapons/dr_2h_hammer.png');
  }
  .dr_2h_pick {
    background: url('../../assets/images/weapons/dr_2h_pick.png');
  }
  .dr_dual_wield_axes {
    background: url('../../assets/images/weapons/dr_dual_wield_axes.png');
  }
  .dr_shield_axe {
    background: url('../../assets/images/weapons/dr_shield_axe.png');
  }
  .dr_shield_hammer {
    background: url('../../assets/images/weapons/dr_shield_hammer.png');
  }
  .es_1h_flail {
    background: url('../../assets/images/weapons/es_1h_flail.png');
  }
  .es_1h_mace {
    background: url('../../assets/images/weapons/es_1h_mace.png');
  }
  .es_1h_sword {
    background: url('../../assets/images/weapons/es_1h_sword.png');
  }
  .es_2h_hammer {
    background: url('../../assets/images/weapons/es_2h_hammer.png');
  }
  .es_2h_sword {
    background: url('../../assets/images/weapons/es_2h_sword.png');
  }
  .es_2h_sword_executioner {
    background: url('../../assets/images/weapons/es_2h_sword_executioner.png');
  }
  .es_halberd {
    background: url('../../assets/images/weapons/es_halberd.png');
  }
  .es_mace_shield {
    background: url('../../assets/images/weapons/es_mace_shield.png');
  }
  .es_sword_shield {
    background: url('../../assets/images/weapons/es_sword_shield.png');
  }
  .we_1h_sword {
    background: url('../../assets/images/weapons/we_1h_sword.png');
  }
  .we_2h_axe {
    background: url('../../assets/images/weapons/we_2h_axe.png');
  }
  .we_2h_sword {
    background: url('../../assets/images/weapons/we_2h_sword.png');
  }
  .we_dual_wield_daggers {
    background: url('../../assets/images/weapons/we_dual_wield_daggers.png');
  }
  .we_dual_wield_sword_dagger {
    background: url('../../assets/images/weapons/we_dual_wield_sword_dagger.png');
  }
  .we_dual_wield_swords {
    background: url('../../assets/images/weapons/we_dual_wield_swords.png');
  }
  .we_spear {
    background: url('../../assets/images/weapons/we_spear.png');
  }
  .wh_1h_axe {
    background: url('../../assets/images/weapons/wh_1h_axe.png');
  }
  .wh_1h_falchion {
    background: url('../../assets/images/weapons/wh_1h_falchion.png');
  }
  .wh_2h_sword {
    background: url('../../assets/images/weapons/wh_2h_sword.png');
  }
  .wh_fencing_sword {
    background: url('../../assets/images/weapons/wh_fencing_sword.png');
  }
  .bw_skullstaff_beam {
    background: url('../../assets/images/weapons/bw_skullstaff_beam.png');
  }
  .bw_skullstaff_fireball {
    background: url('../../assets/images/weapons/bw_skullstaff_fireball.png');
  }
  .bw_skullstaff_flamethrower {
    background: url('../../assets/images/weapons/bw_skullstaff_flamethrower.png');
  }
  .bw_skullstaff_geiser {
    background: url('../../assets/images/weapons/bw_skullstaff_geiser.png');
  }
  .bw_skullstaff_spear {
    background: url('../../assets/images/weapons/bw_skullstaff_spear.png');
  }
  .dr_crossbow {
    background: url('../../assets/images/weapons/dr_crossbow.png');
  }
  .dr_drake_pistol {
    background: url('../../assets/images/weapons/dr_drake_pistol.png');
  }
  .dr_drakegun {
    background: url('../../assets/images/weapons/dr_drakegun.png');
  }
  .dr_handgun {
    background: url('../../assets/images/weapons/dr_handgun.png');
  }
  .dr_rakegun {
    background: url('../../assets/images/weapons/dr_rakegun.png');
  }
  .es_blunderbuss {
    background: url('../../assets/images/weapons/es_blunderbuss.png');
  }
  .es_handgun {
    background: url('../../assets/images/weapons/es_handgun.png');
  }
  .es_longbow {
    background: url('../../assets/images/weapons/es_longbow.png');
  }
  .es_repeating_handgun {
    background: url('../../assets/images/weapons/es_repeating_handgun.png');
  }
  .we_crossbow_repeater {
    background: url('../../assets/images/weapons/we_crossbow_repeater.png');
  }
  .we_longbow {
    background: url('../../assets/images/weapons/we_longbow.png');
  }
  .we_shortbow {
    background: url('../../assets/images/weapons/we_shortbow.png');
  }
  .we_shortbow_hagbane {
    background: url('../../assets/images/weapons/we_shortbow_hagbane.png');
  }
  .wh_brace_of_pistols {
    background: url('../../assets/images/weapons/wh_brace_of_pistols.png');
  }
  .wh_crossbow {
    background: url('../../assets/images/weapons/wh_crossbow.png');
  }
  .wh_crossbow_repeater {
    background: url('../../assets/images/weapons/wh_crossbow_repeater.png');
  }
  .wh_repeating_pistols {
    background: url('../../assets/images/weapons/wh_repeating_pistols.png');
  }

  .es_mace_and_sword {
    background: url('../images/weapons/es_mace_and_sword.png');
  }
  .es_spear {
    background: url('../images/weapons/es_spear.png');
  }
  .dr_hammers {
    background: url('../images/weapons/dr_hammers.png');
  }
  .dr_throwing_axes {
    background: url('../images/weapons/dr_throwing_axes.png');
  }
  .we_axe {
    background: url('../images/weapons/we_axe.png');
  }
  .we_spear_and_shield {
    background: url('../images/weapons/we_spear_and_shield.png');
  }
  .wh_axe_and_falchion {
    background: url('../images/weapons/wh_axe_and_falchion.png');
  }
  .wh_billhook {
    background: url('../images/weapons/wh_billhook.png');
  }
  .bw_crowbill {
    background: url('../images/weapons/bw_crowbill.png');
  }
  .bw_flail {
    background: url('../images/weapons/bw_flail.png');
  }
  .es_1h_sword_shield_breton {
    background: url('../images/weapons/es_1h_sword_shield_breton.png');
  }
  .es_bastard_sword {
    background: url('../images/weapons/es_bastard_sword.png');
  }
  .dr_2h_cog_hammer {
    background: url('../images/weapons/dr_2h_cog_hammer.png');
  }
  .dr_steam_pistol {
    background: url('../images/weapons/dr_steam_pistol.png');
  }
  .es_deus_01 {
    background: url('../images/weapons/es_deus_01.png');
  }
  .dr_deus_01 {
    background: url('../images/weapons/dr_deus_01.png');
  }
  .we_deus_01 {
    background: url('../images/weapons/we_deus_01.png');
  }
  .wh_deus_01 {
    background: url('../images/weapons/wh_deus_01.png');
  }
  .bw_deus_01 {
    background: url('../images/weapons/bw_deus_01.png');
  }
  .we_life_staff {
    background: url('../images/weapons/we_life_staff.png');
  }
  .we_javelin {
    background: url('../images/weapons/we_javelin.png');
  }